import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {authenticationService} from '../services';
import {hostname, FRONTEND_URL} from '../config/endpoints';
import {Redirect} from "react-router-dom";
import {CircularProgress} from "@material-ui/core";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="/">
                LMS Teacher's Dashboard
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '25%',
        left: 'calc(50% - 54px)',
    },
    text: {
        fontSize: 24,
        paddingTop: 20,
        marginLeft: -25,
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
        color: '#1a90ff',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login(props) {
    const classes = useStyles();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();


    useEffect(() => {
        if (hostname !== 'localhost') {
            window.location = 'https://' + FRONTEND_URL + '/login';
        }
    });


    useEffect(() => {
        if (!!authenticationService.currentUserValue) {
            const {from} = props.location.state || {from: {pathname: "/"}};
            props.history.push(from);
        }
    }, [props]);


    const onSubmit = e => {
        e.preventDefault();

        authenticationService.login(username, password).then(() => {
            const {from} = props.location.state || {from: {pathname: "/"}};
            props.history.push(from);
        });
    }

    return (
        <>
            {
                hostname !== 'localhost' ?
                    <div className={classes.root}>
                        <CircularProgress
                            variant="determinate"
                            className={classes.bottom}
                            size={40}
                            thickness={4}
                            {...props}
                            value={100}
                        />
                        <CircularProgress
                            variant="indeterminate"
                            disableShrink
                            className={classes.top}
                            classes={{
                                circle: classes.circle,
                            }}
                            size={40}
                            thickness={4}
                            {...props}
                        />
                        <Typography className={classes.text}>Loading...</Typography>
                    </div>
                :
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <div className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign in
                            </Typography>
                            <form
                                className={classes.form}
                                onSubmit={onSubmit}
                                noValidate
                            >
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={e => setUsername(e.currentTarget.value)}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={e => setPassword(e.currentTarget.value)}
                                />
                                <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label="Remember me"
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Sign In
                                </Button>
                                {/*<Grid container>*/}
                                {/*    <Grid item xs>*/}
                                {/*        <Link href="#" variant="body2">*/}
                                {/*            Forgot password?*/}
                                {/*        </Link>*/}
                                {/*    </Grid>*/}
                                {/*</Grid>*/}
                            </form>
                        </div>
                        <Box mt={8}>
                            <Copyright />
                        </Box>
                    </Container>
            }
        </>
    );
}
