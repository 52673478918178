import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';

import note from '../../images/note.png';
import noteEmpty from '../../images/note-empty.png';
import check from '../../images/check.png';
import checkAbsent from '../../images/check-absent.png';
import checkEmpty from '../../images/check-empty.png';


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'nowrap',
        textAlign: 'left',
        padding: 0,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
    },
    notesContainer: {
        flexBasis: 70,
        flexShrink: 0,
        textAlign: 'right',
        paddingTop: 20,
        paddingBottom: 20,
        height: '100%',
        boxSizing: 'border-box',
        cursor: 'pointer',
    },
    notes: {
        padding: 0,
        height: 40,
        borderRight: '3px solid #9C9C9C',
        boxSizing: 'border-box',
        cursor: 'pointer',
        paddingRight: 10,
    },
    checkbox: {
        flexBasis: 50,
        flexShrink: 0,
        paddingTop: 19,
        paddingBottom: 19,
        textAlign: 'center',
        padding: '19px 20px 19px 10px',
        height: '100%',
        boxSizing: 'border-box',
        cursor: 'pointer',
    },
    student: {
        padding: 0,
        height: '100%',
        fontSize: '17px',
        lineHeight: '78px',
        boxSizing: 'border-box',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    checkLabel: {
        display: 'flex',
        flexGrow: 1,
        padding: 0,
        width: 'calc(100% - 70px)',
        height: '100%',
        boxSizing: 'border-box',
        cursor: 'pointer',
    },
    dbIcon: {
        width: 40,
        height: 40,
    },
}));


const Attendance = props => {
    const [isChecked, setIsChecked] = useState(props.attendance.Attendance);
    const [notes, setNotes] = useState(props.notes);

    useEffect(() => {
        setNotes(props.notes);
    }, [props.notes]);


    const classes = useStyles();


    const onNotesClick = () => {
        if (props.onNotesClick) {
            props.onNotesClick(props.idx);
        }
    }


    const onAttendanceClick = e => {
        setIsChecked(!isChecked);

        if (props.changeAttendance && (typeof props.idx === 'number')) {
            props.changeAttendance(props.idx);
        }
    }


    return (
        <div
            className={classes.container}
        >
            <div
                className={classes.notesContainer}
                style={{
                    backgroundColor: (
                        isChecked ?
                            '#E1F7C9' :
                            isChecked === false ?
                                '#EDEDED' :
                                '#FFFFFF'
                    )
                }}
                onClick={onNotesClick}
            >
                <div className={classes.notes}>
                    {
                        notes && notes.length > 0 ?
                            <img src={note} alt="Note" className={classes.dbIcon} />
                        :
                            <img src={noteEmpty} alt="No notes" className={classes.dbIcon} />
                    }
                </div>
            </div>

            <div
                className={classes.checkLabel}
                style={{
                    backgroundColor: (
                        isChecked ?
                            '#E1F7C9' :
                            isChecked === false ?
                                '#EDEDED' :
                                '#FFFFFF'
                    )
                }}
                onClick={onAttendanceClick}
            >
                <div className={classes.checkbox}>
                    {
                        isChecked ?
                            <img src={check} alt="Presents" className={classes.dbIcon} />
                        :
                            isChecked === false ?
                                <img src={checkAbsent} alt="Absent" className={classes.dbIcon} />
                            :
                                <img src={checkEmpty} alt="Not set" className={classes.dbIcon} />
                    }
                </div>

                <div className={classes.student}>
                    {props.attendance ? props.attendance.FirstName + ' ' + props.attendance.LastName : null}
                </div>
            </div>
        </div>
    );
}

export default Attendance;
