import React, {useState, useEffect} from 'react';
import {fetchIt, isToday} from "../../helpers";
import moment from "moment";
import {Button, Grid, Typography} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import Event from "./Event";
import {userDataService, notificationService} from "../../services";
import {Link, useHistory} from "react-router-dom";
import {TimeComponent} from "../index";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CircularProgress from '@material-ui/core/CircularProgress';



const useStyles = makeStyles(theme => ({
    title: {
        color: '#F5A623',
        textAlign: 'center',
        padding: theme.spacing(1),
        fontSize: '25px',
    },
    secondTitle: {
        color: '#403D39',
        textAlign: 'center',
        padding: theme.spacing(1),
        fontSize: '14px',
    },
    button: {
        minWidth: 60,
        marginLeft: 15,
        padding: 0,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        '& .MuiButton-label': {
            minWidth: 1,
            textDecoration: 'none',
            textTransform: 'none',
            padding: '5px 10px',
            fontSize: '13px',
            lineHeight: '13px',
        }
    },
    squareButton: {
        minWidth: 1,
        marginLeft: 15,
        padding: 0,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        '& .MuiButton-label': {
            minWidth: 1,
            textDecoration: 'none',
            textTransform: 'none',
            padding: 0,
            fontSize: '13px',
            lineHeight: '13px',
        }
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 300,
        alignItems: 'center',
        justifyContent: 'center',
    }
}));


const DailyView = props => {
    const [events, setEvents] = useState([]);
    const [currentTime, setCurrentTime] = useState(moment());
    const [currentDate, setCurrentDate] = useState(
        props.match.params.date ?
            moment(props.match.params.date, 'YYYY-MM-DD') :
            moment()
    );
    const [shouldFetch, setShouldFetch] = useState(false);
    const [userData, setUserData] = useState(null);
    const [isTodayEvent, setIsTodayEvent] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    let history = useHistory();


    const classes = useStyles();

    if (!props.match.params.date) {
        history.replace('/day/' + moment().format('YYYY-MM-DD'));
    }


    useEffect(() => {
        userDataService.userData.subscribe(data => setUserData(data));

        const tick = setInterval(() => {
                setCurrentTime(moment());
            }, 1000);

        return () => clearInterval(tick);
    }, []);


    useEffect(() => {
        setIsTodayEvent(isToday(currentDate));

        fetchEvents();
    }, [shouldFetch]); // eslint-disable-line


    useEffect(() => {
        setIsTodayEvent(isToday(currentDate));

        fetchEvents();
    }, []); // eslint-disable-line


    const fetchEvents = () => {
        const today = currentDate.format('YYYY-MM-DD');
        fetchIt(
            'GET_LIST',
            'dashboard',
            {fromDate: today, toDate: today},
        ).then(response => {
            switch (response.status) {
                case 200:
                    setEvents(response.data.sort((a, b) => {
                        const d1 = moment(moment(a.StartDateTime, 'M/D/YYYY h:mm:ss A').format('h:mm:ss A'), 'h:mm:ss A');
                        const d2 = moment(moment(b.StartDateTime, 'M/D/YYYY h:mm:ss A').format('h:mm:ss A'), 'h:mm:ss A');

                        return moment(d1).diff(d2);
                    }));

                    break;

                case 404:
                    let errorMessage = '';

                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    notificationService.notify({
                        message: errorMessage,
                        type: 'warning',
                    });

                    break;

                default:
                    notificationService.notify({
                        message: 'Oops, something went wrong!',
                        type: 'error',
                    });
            }
        }).catch(() => {

        }).then(() => {
            setIsLoading(false);
        });
    }


    const back = () => {
        const nextDate = currentDate.subtract(1, 'days');
        setIsLoading(true);
        setCurrentDate(nextDate);
        setShouldFetch(!shouldFetch);

        history.replace('/day/' + nextDate.format('YYYY-MM-DD'));
    }


    const today = () => {
        const nextDate = moment();
        setIsLoading(true);
        setCurrentDate(nextDate);
        setShouldFetch(!shouldFetch);

        history.replace('/day/' + nextDate.format('YYYY-MM-DD'));
    }


    const forward = () => {
        const nextDate = currentDate.add(1, 'days');
        setIsLoading(true);
        setCurrentDate(nextDate);
        setShouldFetch(!shouldFetch);

        history.replace('/day/' + nextDate.format('YYYY-MM-DD'));
    }



    return (
        <>
            <Typography className={classes.title} variant='h4'>
                {
                    isTodayEvent ?
                        <TimeComponent />
                    :
                        <>{currentDate.format('dddd, D MMMM')}</>
                }
            </Typography>

            {
                userData && !isLoading ?
                    <Typography className={classes.secondTitle} variant='h5'>
                        {userData.FirstName}, you have {events.length ? events.length : 'no'} classes {isTodayEvent ? 'today' : 'this day'}
                    </Typography>
                :
                    null
            }

            { !isLoading ?
                <>
                    <Grid
                        container
                        style={{marginTop: 20, marginBottom: 6}}
                    >
                        <Grid
                            item
                            xs={6}
                        >
                            <Link
                                to={'/week/' + currentDate.format('YYYY-MM-DD')}
                                style={{textDecoration: 'none'}}
                            >
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    className={classes.button}
                                    style={{marginLeft: 40}}
                                >
                                    week
                                </Button>
                            </Link>
                            <Link
                                to={'/month/' + currentDate.format('YYYY-MM-DD')}
                                style={{textDecoration: 'none'}}
                            >
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    className={classes.button}
                                >
                                    month
                                </Button>
                            </Link>
                        </Grid>

                        <Grid
                            item
                            xs={6}
                            style={{textAlign: 'right'}}
                        >
                            <Button
                                variant='outlined'
                                onClick={back}
                                className={classes.squareButton}
                            >
                                <ArrowBackIcon />
                            </Button>
                            <Button
                                variant='outlined'
                                onClick={today}
                                className={classes.button}
                            >
                                today
                            </Button>
                            <Button
                                variant='outlined'
                                onClick={forward}
                                className={classes.squareButton}
                                style={{marginRight: 40}}
                            >
                                <ArrowForwardIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </>
            :
                <div className={classes.main}>
                    <CircularProgress />
                </div>
            }

            { !isLoading &&
                events.map((event, idx) => <Event key={event.Id} idx={idx} event={event} currentTime={currentTime} />)
            }
        </>
    );
}

export default DailyView;
