import React, {useEffect, useRef, useState} from "react";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import moment from "moment";
import {Button, Grid, Typography} from "@material-ui/core";
import {Link, Redirect} from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {makeStyles} from "@material-ui/core/styles";
import {notificationService, userDataService} from "../../services";
import {fetchIt} from "../../helpers";
import {useResizeDetector} from "react-resize-detector";
import {useHistory} from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    title: {
        color: '#F5A623',
        textAlign: 'center',
        padding: theme.spacing(1),
        fontSize: '25px',
    },
    secondTitle: {
        color: '#403D39',
        textAlign: 'center',
        padding: theme.spacing(1),
        fontSize: '15px',
    },
    monthTitle: {
        color: '#403D39',
        textAlign: 'center',
        padding: theme.spacing(1),
        fontSize: '25px',
    },
    weekday: {
        borderBottom: '1px solid #9C9C9C',
        height: 250,
        padding: 0,
        boxSizing: 'border-box',
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },
    left: {
        boxSizing: 'border-box',
        textDecoration: 'none',
    },
    right: {
        boxSizing: 'border-box',
        textDecoration: 'none',
        textAlign: 'right',
        flexBasis: 100,
        flexShrink: 0,
    },
    more: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        textAlign: 'center',
        width: '100%',
        flexBasis: '100%'
    },
    button: {
        minWidth: 60,
        marginLeft: 15,
        padding: 0,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        '& .MuiButton-label': {
            minWidth: 1,
            textDecoration: 'none',
            textTransform: 'none',
            padding: '5px 10px',
            fontSize: '13px',
            lineHeight: '13px',
        }
    },
    squareButton: {
        minWidth: 1,
        marginLeft: 15,
        padding: 0,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        '& .MuiButton-label': {
            minWidth: 1,
            textDecoration: 'none',
            textTransform: 'none',
            padding: 0,
            fontSize: '13px',
            lineHeight: '13px',
        }
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 300,
        alignItems: 'center',
        justifyContent: 'center'
    }
}));


const MonthlyView = props => {
    const [shouldFetch, setShouldFetch] = useState(false);
    const [events, setEvents] = useState([]);
    const [redirectTo, setRedirectTo] = useState(false);
    const [selectedDate, setSelectedDate] = useState(props.match.params.date ? moment(props.match.params.date) : moment());
    const [userData, setUserData] = useState(null);
    const {width, ref} = useResizeDetector();
    const [isLoading, setIsLoading] = useState(true);
    let history = useHistory();


    const calendar = useRef(null);

    useEffect(() => {
        fetchEvents();
    }, [shouldFetch]); // eslint-disable-line


    useEffect(() => {
        if (!isLoading && calendar.current){
            let calendarApi = calendar.current.getApi();

            calendarApi.gotoDate(selectedDate.toDate());
        }  
    }); // eslint-disable-line


    useEffect(() => {
        userDataService.userData.subscribe(data => setUserData(data));

        setShouldFetch(!shouldFetch);
    }, []); // eslint-disable-line


    const classes = useStyles();


    const fetchEvents = () => {
        fetchIt(
            'GET_LIST',
            'dashboard',
            {
                fromDate: selectedDate.startOf('month').format('YYYY-MM-DD'),
                toDate: selectedDate.endOf('month').format('YYYY-MM-DD')
            },
        ).then(response => {
            switch (response.status) {
                case 200:
                    let newEvents = [];
                    for (let t = 0; t < response.data.length; t++) {
                        if (
                            response.data[t] &&
                            response.data[t].id &&
                            response.data[t].StartDateTime &&
                            response.data[t].EndDateTime
                        ) {
                            let event = response.data[t];

                            event.title = event.CourseName;
                            event.start = moment(event.StartDateTime, 'M/D/YYYY h:mm:ss A').format();
                            event.end = moment(event.EndDateTime, 'M/D/YYYY h:mm:ss A').format();

                            newEvents.push(event);
                        }
                    }

                    setEvents(newEvents);

                    break;

                case 404:
                    let errorMessage = '';

                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    notificationService.notify({
                        message: errorMessage,
                        type: 'warning',
                    });

                    break;

                default:
                    notificationService.notify({
                        message: 'Oops, something went wrong!',
                        type: 'error',
                    });
            }
        }).catch(() => {

        }).then(() => {
            setIsLoading(false);
        });
    }


    const dayCellContent = args => {
        const date = moment(args.date);

        return (
            <Typography variant='subtitle1'>
                {date.format('D MMM')}
            </Typography>
        );
    }


    const moreLinkContent = args => {
        return (
            <Typography variant='subtitle1' component='div' className={classes.more} noWrap style={{position: (width < 1500 ? 'static' : 'absolute')}}>
                {
                    args.num + ' more...'
                }
            </Typography>
        );
    }


    const eventContent = args => {
        const event = args.event.extendedProps;

        return (
            <Grid item xs={12}>
                <Typography variant='subtitle2' component='div' noWrap>
                    {moment(event.StartDateTime, 'M/D/YYYY h:mm:ss A').format('h:mmA')} {event.CourseName}
                </Typography>
            </Grid>
        );
    }


    const moreLinkClick = info => {
        setRedirectTo(moment(info.date).format('YYYY-MM-DD'));
    }


    const eventClick = info => {
        setRedirectTo(moment(info.event.start).format('YYYY-MM-DD'));
    }


    const dateClick = info => {
        setRedirectTo(info.dateStr);
    }


    const back = () => {
        let calendarApi = calendar.current.getApi();

        calendarApi.prev();

        setIsLoading(true);
        const nextDate = moment(calendarApi.getDate());
        setSelectedDate(nextDate);
        setShouldFetch(!shouldFetch);

        history.replace('/month/' + nextDate.format('YYYY-MM-DD'));
    }


    const today = () => {
        let calendarApi = calendar.current.getApi();

        calendarApi.today();

        setIsLoading(true);
        const nextDate = moment(calendarApi.getDate());
        setSelectedDate(nextDate);
        setShouldFetch(!shouldFetch);

        history.replace('/month/' + nextDate.format('YYYY-MM-DD'));
    }


    const forward = () => {
        let calendarApi = calendar.current.getApi();

        calendarApi.next();

        setIsLoading(true);
        const nextDate = moment(calendarApi.getDate());
        setSelectedDate(nextDate);
        setShouldFetch(!shouldFetch);

        history.replace('/month/' + nextDate.format('YYYY-MM-DD'));
    }


    if (redirectTo) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{pathname: '/day/' + redirectTo, state: {}}} />;
    }


    return (
        <>
            {
                userData ?
                    <Typography className={classes.title} variant='h4'>
                        {userData.FirstName}'s Dashboard
                    </Typography>
                    :
                    null
            }

            <Typography className={classes.secondTitle} variant='h5'>
                Calendar
            </Typography>

            { 
                !isLoading ?
                    <>
                        <Grid
                            ref={ref}
                            container
                            style={{marginTop: 20, marginBottom: 6}}
                        >
                            <Grid
                                item
                                xs={4}
                            >
                                <Link
                                    to={'/week/' + selectedDate.format('YYYY-MM-DD')}
                                    style={{textDecoration: 'none'}}
                                >
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        className={classes.button}
                                        style={{marginLeft: 40}}
                                    >
                                        week
                                    </Button>
                                </Link>
                                <Button variant='contained' color='primary' className={classes.button}>
                                    month
                                </Button>
                            </Grid>

                            <Grid
                                item
                                xs={4}
                            >
                                <Typography className={classes.monthTitle} variant='h4'>
                                    {selectedDate.format('MMMM')}
                                </Typography>

                            </Grid>

                            <Grid
                                item
                                xs={4}
                                style={{textAlign: 'right'}}
                            >
                                <Button
                                    variant='outlined'
                                    onClick={back}
                                    className={classes.squareButton}
                                >
                                    <ArrowBackIcon />
                                </Button>
                                <Button
                                    variant='outlined'
                                    onClick={today}
                                    className={classes.button}
                                >
                                    today
                                </Button>
                                <Button
                                    variant='outlined'
                                    onClick={forward}
                                    className={classes.squareButton}
                                    style={{marginRight: 40}}
                                >
                                    <ArrowForwardIcon />
                                </Button>
                            </Grid>
                        </Grid>

                        <FullCalendar
                            ref={calendar}
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView='dayGridMonth'
                            headerToolbar={false}
                            dayCellContent={dayCellContent}
                            moreLinkContent={moreLinkContent}
                            eventContent={eventContent}
                            moreLinkClick={moreLinkClick}
                            eventClick={eventClick}
                            dateClick={dateClick}
                            weekNumberCalculation='ISO'
                            dayMaxEventRows={4}
                            displayEventTime={false}
                            handleWindowResize={true}
                            events={events}
                        />
                    </>
            :
                    <div className={classes.main}>
                        <CircularProgress />
                    </div> 
            }
        </>
    )
}

export default MonthlyView;
