import React from 'react';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'nowrap',
        textAlign: 'left',
        padding: 0,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        backgroundClip: 'padding-box',
    },
}));


const EmptyBox = () => {
    const classes = useStyles();

    return (
        <div
            className={classes.container}
        >

        </div>
    );
}

export default EmptyBox;
