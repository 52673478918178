import React, {Fragment, useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {Grid, Typography} from "@material-ui/core";
import moment from "moment";


const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexWrap: 'nowrap',
        textAlign: 'left',
        padding: '15px',
        width: '100%',
        height: 250,
        borderBottom: '1px solid #9C9C9C',
        boxSizing: 'border-box !important',
        textDecoration: 'none',
        backgroundClip: 'padding-box',
    },
    more: {
        position: 'absolute',
        left: 0,
        bottom: 15,
        textAlign: 'center',
        width: '100%',
        flexBasis: '100%'
    }
}));


const DayOfWeek = props => {
    const [containerStyle, setContainerStyle] = useState(
        {
            color: '#403D39',
            backgroundColor: '#FCFFF3',
        }
    );
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [width, setWidth] = useState(props.parentWidth);


    useEffect(() => {
        setWidth(props.parentWidth);
    }, [props.parentWidth]);


    useEffect(() => {
        let newStyle = {
            color: '#403D39',
            backgroundColor: '#FCFFF3',
        };

        if (props.isToday) {
            newStyle = {
                color: '#FFFFFF',
                backgroundColor: '#3F7600',
            }
        }

        setContainerStyle(newStyle);
        setShouldRefresh(!shouldRefresh);
    }, [props.isToday]); // eslint-disable-line


    const classes = useStyles();


    const renderEvents = () => {
        const events = props.events;

        let eventsToShow = 3;
        if (events.length < 3) {
            eventsToShow = events.length;
        }

        let result = [];
        for (let t = 0; t < eventsToShow; t++) {
            result.push(
                <Grid key={events[t].Id} item xs={12}>
                    <Typography variant='subtitle2' component='div' noWrap>
                        {moment(events[t].StartDateTime, 'M/D/YYYY h:mm:ss A').format('h:mmA')} {events[t].CourseName}
                    </Typography>
                </Grid>
            )
        }

        return result;
    }


    return (
        <>
            <Link
                to={'/day/' + props.selectedDate.format('YYYY-MM-DD')}
                className={classes.container}
                style={containerStyle}
            >
                <Grid container style={{paddingBottom: 15, alignContent: 'flex-start'}}>
                    <Grid item xs={9} className={classes.left}>
                        <Typography variant='subtitle1' style={{paddingBottom: 25}}>
                            {props.day}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.right} style={{paddingBottom: 25}}>
                        <Typography variant='subtitle1'>
                            {props.selectedDate.format('D MMM')}
                        </Typography>
                    </Grid>

                    {renderEvents()}

                    {
                        props.events.length > 3 ?
                            <Typography variant='subtitle1' className={classes.more} noWrap>
                                {
                                    (props.events.length - 3) + ' more...'
                                }
                            </Typography>
                        : null
                    }

                    {
                        !props.events.length ?
                            <Typography variant='subtitle1' component='div' className={classes.more} noWrap style={{position: (width < 1200 ? 'static' : 'absolute')}}>
                                You have no classes for this day
                            </Typography>
                        : null
                    }
                </Grid>
            </Link>
        </>
    );
}

export default DayOfWeek;
