import React, {useState, useEffect} from 'react';
import moment from "moment";


const TimeComponent = props => {
    const [currentTime, setCurrentTime] = useState(moment());

    useEffect(() => {
        const tick = setInterval(() => {
            setCurrentTime(moment());
        }, 1000);

        return () => clearInterval(tick);
    }, []); // eslint-disable-line


    return (
        <span>
            {currentTime.format('dddd, D MMMM, h:mm A')}
        </span>
    )
}

export default TimeComponent;
