import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, CircularProgress} from "@material-ui/core";
import {Redirect} from "react-router";
import Cookies from 'universal-cookie';
import {currentUserSubject, userDataSubject, schoolDataSubject} from '../services';
import {FRONTEND_URL} from '../config/endpoints';


const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: '25%',
        left: 'calc(50% - 54px)',
    },
    errorRoot: {
        position: 'absolute',
        top: '25%',
        left: 'calc(50% - 160px)',
        width: 320
    },
    text: {
        fontSize: 24,
        paddingTop: 20,
        marginLeft: -25,
    },
    errorTitle: {
        fontSize: 18,
        paddingTop: 20,
        color: 'darkgrey',
    },
    errorText: {
        fontSize: 18,
        paddingTop: 20,
        color: 'grey',
    },
    bottom: {
        color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
        color: '#1a90ff',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
}));


const AutoLogin = props => {
    const [redirect, setRedirect] = useState(false);

    const classes = useStyles();

    const cookies = new Cookies();

    useEffect(() => {
        const user = cookies.get('lms_user');

        if (!user) {
            setRedirect('/login');
        } else {
            localStorage.setItem('user', JSON.stringify(user));
            currentUserSubject.next(user);

            const account = cookies.get('lms_user_account');
            localStorage.setItem('account', JSON.stringify(account));
            userDataSubject.next(account);

            const school = cookies.get('lms_user_school');
            localStorage.setItem('school', JSON.stringify(school));
            schoolDataSubject.next(school);
            cookies.remove(
                'lms_user',
                {
                    path: '/',
                    domain: '.' + FRONTEND_URL,
                }
            );

            cookies.remove(
                'lms_user_account',
                {
                    path: '/',
                    domain: '.' + FRONTEND_URL,
                }
            );

            cookies.remove(
                'lms_user_school',
                {
                    path: '/',
                    domain: '.' + FRONTEND_URL,
                }
            );

            setRedirect('/');
        }
    }, []); // eslint-disable-line


    const {staticContext, ...other} = props;


    return (
        <div className={classes.root}>
            {
                !redirect ?
                    <>
                        <CircularProgress
                            variant="determinate"
                            className={classes.bottom}
                            size={40}
                            thickness={4}
                            {...other}
                            value={100}
                        />
                        <CircularProgress
                            variant="indeterminate"
                            disableShrink
                            className={classes.top}
                            classes={{
                                circle: classes.circle,
                            }}
                            size={40}
                            thickness={4}
                            {...other}
                        />
                        <Typography className={classes.text}>Loading...</Typography>
                    </>
                :
                    <Redirect to={redirect} />
            }
        </div>
    );
};

export default AutoLogin;
