import React, {useState, useEffect} from 'react';
import moment from "moment";


const EventRemainingTime = props => {
    const [currentTime, setCurrentTime] = useState(moment());
    const [eventStart, setEventStart] = useState(moment());
    const [eventEnd, setEventEnd] = useState(moment());


    useEffect(() => {
        const start = moment(moment(props.event.StartDateTime, 'M/D/YYYY h:mm:ss A'));
        const end = moment(moment(props.event.EndDateTime, 'M/D/YYYY h:mm:ss A'));
        setEventStart(start);
        setEventEnd(end);
    }, [props.event.Id, props.event]);


    useEffect(() => {
        const start = moment(moment(props.event.StartDateTime, 'M/D/YYYY h:mm:ss A'));
        const end = moment(moment(props.event.EndDateTime, 'M/D/YYYY h:mm:ss A'));
        setEventStart(start);
        setEventEnd(end);

        const tick = setInterval(() => {
            setCurrentTime(moment());
        }, 1000);

        return () => clearInterval(tick);
    }, []); // eslint-disable-line


    return (
        <>
            {eventStart.format('h:mm A')} -&nbsp;
            {eventEnd.format('h:mm A')},
            {
                currentTime.isBefore(eventStart) ?
                    ' not started yet'
                :
                    currentTime.isAfter(eventEnd) ?
                        ' ended'
                    :
                        ' ' + moment.duration(eventEnd.diff(currentTime)).get('minutes') + 'min. remaining'

            }
        </>
    )
}

export default EventRemainingTime;
