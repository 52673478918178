export const approvedRoles = [
    'Teacher',
];

export const weekdays = [
    {
        id: 'Monday',
        name: 'Monday'
    },
    {
        id: 'Tuesday',
        name: 'Tuesday'
    },
    {
        id: 'Wednesday',
        name: 'Wednesday'
    },
    {
        id: 'Thursday',
        name: 'Thursday'
    },
    {
        id: 'Friday',
        name: 'Friday'
    },
    {
        id: 'Saturday',
        name: 'Saturday'
    },
    {
        id: 'Sunday',
        name: 'Sunday'
    },
];

export const quickNotes = [
    'Late',
    'Very late',
    'Didn\'t show up',
];
