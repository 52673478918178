import React, {useState, useEffect} from 'react';
import {fetchIt} from "../../helpers";
import moment from "moment";
import {Button, Grid, Typography} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {makeStyles} from '@material-ui/core/styles';
import DayOfWeek from "./DayOfWeek";
import {userDataService, notificationService} from "../../services";
import {TimeComponent, EmptyBox} from '../';
import {useResizeDetector} from "react-resize-detector";
import {Link} from "react-router-dom";
import {useHistory} from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';



const useStyles = makeStyles(theme => ({
    title: {
        color: '#F5A623',
        textAlign: 'center',
        padding: theme.spacing(1),
        fontSize: '25px',
    },
    secondTitle: {
        color: '#403D39',
        textAlign: 'center',
        padding: theme.spacing(1),
        fontSize: '15px',
    },
    weekday: {
        borderBottom: '1px solid #9C9C9C',
        height: 250,
        padding: 0,
        boxSizing: 'border-box',
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },
    button: {
        minWidth: 60,
        marginLeft: 15,
        padding: 0,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        '& .MuiButton-label': {
            minWidth: 1,
            textDecoration: 'none',
            textTransform: 'none',
            padding: '5px 10px',
            fontSize: '13px',
            lineHeight: '13px',
        }
    },
    squareButton: {
        minWidth: 1,
        marginLeft: 15,
        padding: 0,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        '& .MuiButton-label': {
            minWidth: 1,
            textDecoration: 'none',
            textTransform: 'none',
            padding: 0,
            fontSize: '13px',
            lineHeight: '13px',
        }
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 300,
        alignItems: 'center',
        justifyContent: 'center'
    }
}));


export const WeeklyView = props => {
    const [shouldFetch, setShouldFetch] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [currentTime, setCurrentTime] = useState(moment());
    const [selectedDate, setSelectedDate] = useState(moment());
    const [userData, setUserData] = useState(null);
    const [week, setWeek] = useState(moment().isoWeek());
    const [columns, setColumns] = useState(0);
    const [additionalBoxes, setAdditionalBoxes] = useState([]);
    const {width, ref} = useResizeDetector();
    let history = useHistory();


    const classes = useStyles();


    useEffect(() => {
        let newColumns = 0;
        if (width < 600) {
            newColumns = 1;
        } else if (width < 960) {
            newColumns = 2;
        } else if (width < 1280) {
            newColumns = 3;
        } else if (width >= 1280) {
            newColumns = 4;
        }

        setColumns(newColumns);

        calculateAdditionalBoxes();
    }, [width]); // eslint-disable-line


    useEffect(() => {
        fetchEvents();
        setIsLoading(true);
        history.replace('/week/' + selectedDate.format('YYYY-MM-DD'));
    }, [shouldFetch]); // eslint-disable-line


    useEffect(() => {
        userDataService.userData.subscribe(data => setUserData(data));

        if (props.match.params.date) {
            setWeek(moment(props.match.params.date, 'YYYY-MM-DD').isoWeek());
            setSelectedDate(moment().isoWeek(week));
            setShouldFetch(!shouldFetch);
        }
    }, []); // eslint-disable-line


    useEffect(() => {
        setCurrentTime(moment());
        setSelectedDate(moment().isoWeek(week));
        setShouldFetch(!shouldFetch);
    }, [week]); // eslint-disable-line


    const calculateAdditionalBoxes = () => {
        const daysLength = 7;
        if (!width || (width < 600)) {
            setAdditionalBoxes([]);

            return false;
        }

        let additionalBoxesNumber = 0;
        if (width < 960) {
            additionalBoxesNumber = daysLength % 2;
        } else if ((width < 1280) && (daysLength % 3)) {
            additionalBoxesNumber = 3 - (daysLength % 3);
        } else if ((width >= 1280) && (daysLength % 4)) {
            additionalBoxesNumber = 4 - (daysLength % 4);
        }

        let newAdditionalBoxes = [];
        if (additionalBoxesNumber) {
            for (let t = 0; t < additionalBoxesNumber; t++) {
                newAdditionalBoxes.push(t);
            }
        }

        setAdditionalBoxes(newAdditionalBoxes);
    }


    const fetchEvents = () => {
        fetchIt(
            'GET_LIST',
            'dashboard',
            {
                fromDate: selectedDate.startOf('isoWeek').format('YYYY-MM-DD'),
                toDate: selectedDate.endOf('isoWeek').format('YYYY-MM-DD')
            },
        ).then(response => {
            switch (response.status) {
                case 200:
                    let newEvents = {};
                    for (let t = 1; t < 8; t++) {
                        newEvents[moment(t, 'E').format('dddd')] = [];
                    }

                    const sortedEvents = response.data.sort((a, b) => {
                        const d1 = moment(moment(a.StartDateTime, 'M/D/YYYY h:mm:ss A').format('h:mm:ss A'), 'h:mm:ss A');
                        const d2 = moment(moment(b.StartDateTime, 'M/D/YYYY h:mm:ss A').format('h:mm:ss A'), 'h:mm:ss A');

                        return moment(d1).diff(d2);
                    });

                    for (let t = 0; t < sortedEvents.length; t++) {
                        const eventDay = moment(sortedEvents[t].StartDateTime, 'M/D/YYYY h:mm:ss A').format('dddd');
                        newEvents[eventDay].push(sortedEvents[t]);
                    }

                    setEvents(newEvents);

                    break;

                case 404:
                    let errorMessage = '';

                    if (!response.data.HasValidationErrors) {
                        errorMessage = response.data.ErrorMessage;
                    }

                    notificationService.notify({
                        message: errorMessage,
                        type: 'warning',
                    });

                    break;

                default:
                    notificationService.notify({
                        message: 'Oops, something went wrong!',
                        type: 'error',
                    });
            }
        }).catch(() => {

        }).then(() => {
            setIsLoading(false);
        });
    }


    const renderDays = () => {
        let days = [];
        let daysToAdd = 0
        for (const day in events) {
            let selectedDay = moment(selectedDate.startOf('isoWeek').format('YYYY-MM-DD'), 'YYYY-MM-DD');
            let selectedDayToFiff = moment(
                selectedDate.startOf('isoWeek').format('YYYY-MM-DD'),
                'YYYY-MM-DD'
            ).add(daysToAdd, 'days').format('YYYY-MM-DD');

            const isToday = (moment(currentTime.format('YYYY-MM-DD'), 'YYYY-MM-DD').isSame(selectedDayToFiff));

            days.push(
                <Grid
                    key={day}
                    item
                    xs={12} sm={6} md={4} lg={3}
                    className={classes.weekday}
                    style={{
                        borderLeft: ((daysToAdd === 0) || ((daysToAdd % columns) === 0) ? 'none' : '1px solid #9C9C9C'),
                    }}
                >
                    <DayOfWeek
                        selectedDate={selectedDay.add(daysToAdd, 'days')}
                        events={events[day]}
                        day={day}
                        isToday={isToday}
                        week={week}
                        parentWidth={width}
                    />
                </Grid>
            );

            daysToAdd++;
        }

        return days;
    }


    return (
        <>
            {
                userData ?
                    <Typography className={classes.title} variant='h4'>
                        {userData.FirstName}'s Dashboard
                    </Typography>
                :
                    null
            }

            <Typography ref={ref} className={classes.secondTitle} variant='h5'>
                <TimeComponent />
            </Typography>

            {
                !isLoading ?
                    <>
                        <Grid
                            container
                            style={{marginTop: 20}}
                        >
                            <Grid
                                item
                                xs={6}
                            >
                                <Button
                                    variant='contained'
                                    color='primary'
                                    className={classes.button}
                                    style={{marginLeft: 40}}
                                >
                                    week
                                </Button>
                                <Link
                                    to={'/month/' + selectedDate.format('YYYY-MM-DD')}
                                    style={{textDecoration: 'none'}}
                                >
                                    <Button variant='outlined' color='primary' className={classes.button}>
                                        month
                                    </Button>
                                </Link>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                style={{textAlign: 'right'}}
                            >
                                <Button
                                    variant='outlined'
                                    onClick={() => setWeek(week - 1)}
                                    className={classes.squareButton}
                                >
                                    <ArrowBackIcon />
                                </Button>
                                <Button
                                    variant='outlined'
                                    onClick={() => setWeek(currentTime.isoWeek())}
                                    className={classes.button}
                                >
                                    Today
                                </Button>
                                <Button
                                    variant='outlined'
                                    onClick={() => setWeek(week + 1)}
                                    className={classes.squareButton}
                                    style={{marginRight: 40}}
                                >
                                    <ArrowForwardIcon />
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            style={{borderTop: '1px solid #9C9C9C', marginTop: 15, marginBottom: 25}}
                        >
                            {renderDays()}

                            {
                                additionalBoxes ?
                                    additionalBoxes.map(additionalBox => (
                                        <Grid
                                            key={additionalBox}
                                            item
                                            xs={12} sm={6} md={4} lg={3}
                                            className={classes.weekday}
                                            style={{
                                                borderLeft: '1px solid #9C9C9C',
                                            }}
                                        >
                                            <EmptyBox additionalBoxes={additionalBoxes} idx={additionalBox} />
                                        </Grid>
                                    ))
                                    : null
                            }
                        </Grid>
                    </>
                : 
                    <div className={classes.main}>
                        <CircularProgress />
                    </div>      
            }
        </>
    );
}

export default WeeklyView;
