import {BehaviorSubject} from 'rxjs';
import {fetchIt} from "../helpers";
import {userDataSubject, schoolDataSubject} from './user.service';
import Cookies from 'universal-cookie';
import {FRONTEND_URL, hostname} from "../config/endpoints";


export const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () {return currentUserSubject.value}
};

function login(username, password) {
    localStorage.removeItem('user');
    localStorage.removeItem('account');
    localStorage.removeItem('school');

    return fetchIt(
        'post',
        'login',
        {username, password}
    ).then(response => {
        let error = 'Oops, something went wrong!';

        switch (response.status) {
            case 200:
                localStorage.setItem('user', JSON.stringify(response.data));
                currentUserSubject.next(response.data);

                fetchIt(
                    'GET_ONE',
                    'account',
                    {id: 1}
                ).then(accountResponse => {
                    if (accountResponse.status === 200) {
                        localStorage.setItem('account', JSON.stringify(accountResponse.data));
                        userDataSubject.next(accountResponse.data);

                        fetchIt(
                            'GET_LIST',
                            'schools'
                        ).then(schoolResponse => {
                            if (schoolResponse.status === 200) {
                                localStorage.setItem('school', JSON.stringify(schoolResponse.data[0]));
                                schoolDataSubject.next(schoolResponse.data[0]);

                                return Promise.resolve();
                            }

                            return Promise.reject();
                        });

                        return Promise.resolve();
                    }

                    return Promise.reject();
                });

                break;

            case 400:
                authenticationService.logout();
                window.location.reload();

                error = 'The email and/or password are not correct.';

                return Promise.reject(error);

            default:
                authenticationService.logout();
                window.location.reload();

                return Promise.reject(error);
        }
    });
}


function logout() {
    const cookies = new Cookies();
    cookies.set(
        'lms_logging_out',
        1,
        {
            path: '/',
            domain: '.' + FRONTEND_URL,
        }
    );

    localStorage.removeItem('account');
    userDataSubject.next(null);

    localStorage.removeItem('school');
    schoolDataSubject.next(null);

    currentUserSubject.next(null);

    if(localStorage.getItem('user') !== null){
        const refreshToken = JSON.parse(window.localStorage.getItem('user')).refresh_token;
        localStorage.removeItem('user');

        if (hostname === 'localhost') {
            return fetchIt(
                'post',
                'logout',
                {token: refreshToken}
            ).then(response => {
                return;
            }).catch(error => {
                return Promise.resolve();
            }).then(() => {
                // window.location.reload();
            });
        }
    }

    return Promise.resolve();
}
