import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Link} from "react-router-dom";
import Routes from "./Routes";
import {createBrowserHistory} from 'history';
import {makeStyles, unstable_createMuiStrictModeTheme as createMuiTheme} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    MenuItem,
    Portal,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
} from "@material-ui/core";
import {ThemeProvider} from '@material-ui/styles';
import {authenticationService, userDataService} from './services';
import Notification from "./components/Notification";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        color: '#3A3A3A'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    menuLinks: {
        textDecoration: 'none',
        color: '#3A3A3A',
    },
}));


export const history = createBrowserHistory();


const App = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [schoolData, setSchoolData] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);


    const openMenu = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };


    const closeMenu = () => {
        setMenuAnchorEl(null);
    };


    const openUserMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const closeUserMenu = () => {
        setAnchorEl(null);
    };


    useEffect(() => {
        authenticationService.currentUser.subscribe(data => setCurrentUser(data));
        userDataService.userData.subscribe(data => setUserData(data));
        userDataService.schoolData.subscribe(data => setSchoolData(data));
    }, []);


    useEffect(() => {
        closeUserMenu();
    }, [currentUser]);


    const classes = useStyles();


    return (
        <Router history={history}>
            <ThemeProvider theme={theme}>
                {
                    currentUser ?
                        <div className={classes.root}>
                            <AppBar position="static" elevation={0} color='transparent'>
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        className={classes.menuButton}
                                        color="inherit"
                                        aria-label="menu"
                                        onClick={openMenu}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Popper
                                        open={Boolean(menuAnchorEl)}
                                        anchorEl={menuAnchorEl}
                                        role={undefined}
                                        transition
                                        disablePortal
                                        style={{zIndex: 1000000}}
                                    >
                                        {({TransitionProps, placement}) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={closeMenu}>
                                                        <MenuList autoFocusItem={Boolean(menuAnchorEl)}>
                                                            <Link
                                                                to={'/day/' + moment().format('YYYY-MM-DD')}
                                                                className={classes.menuLinks}
                                                            >
                                                                <MenuItem variant='outlined' onClick={closeMenu}>
                                                                    Day View
                                                                </MenuItem>
                                                            </Link>
                                                            <Link
                                                                to={'/week/' + moment().format('YYYY-MM-DD')}
                                                                className={classes.menuLinks}
                                                            >
                                                                <MenuItem variant='outlined' onClick={closeMenu}>
                                                                    Week View
                                                                </MenuItem>
                                                            </Link>
                                                            <Link
                                                                to={'/month/' + moment().format('YYYY-MM-DD')}
                                                                className={classes.menuLinks}
                                                            >
                                                                <MenuItem variant='outlined' onClick={closeMenu}>
                                                                    Month View
                                                                </MenuItem>
                                                            </Link>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>

                                    <Typography variant="h6" className={classes.title}>
                                        {schoolData ? schoolData.SchoolName : null}
                                    </Typography>

                                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openUserMenu}>
                                        {userData ? userData.FirstName + ' ' + userData.LastName : null}
                                    </Button>
                                    <Popper
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        role={undefined}
                                        transition
                                        disablePortal
                                    >
                                        {({TransitionProps, placement}) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={closeUserMenu}>
                                                        <MenuList autoFocusItem={Boolean(anchorEl)} id="menu-list-grow">
                                                            <MenuItem onClick={authenticationService.logout}>Logout</MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </Toolbar>
                            </AppBar>
                        </div>
                    :
                    null
                }

                <div>
                    <Routes />
                </div>

                <Portal>
                    <Notification />
                </Portal>
            </ThemeProvider>
        </Router>
  );
}

export default App;


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#403D39',
        },
    },
});
