import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {PrivateRoute} from "./components/PrivateRoute";
import {EventView, DailyView, WeeklyView, MonthlyView} from "./Views";
import Login from "./Auth/Login";
import AutoLogin from "./Auth/AutoLogin";


const Routes = () => {
    return (
        <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute exact path="/" component={DailyView} />
            <PrivateRoute exact path="/day/:date" component={DailyView} />
            <PrivateRoute exact path="/week" component={WeeklyView} />
            <PrivateRoute exact path="/week/:date" component={WeeklyView} />
            <PrivateRoute exact path="/event/:eventId" component={EventView} />
            <PrivateRoute exact path="/month/:date" component={MonthlyView} />
            <Route exact path="/al" component={AutoLogin} noLayout/>,

            {/*<Route component={FourOFour}/>*/}
        </Switch>
    );
}

export default Routes;
