import React, {useState, useEffect, useLayoutEffect} from 'react';
import {fetchIt, inArray} from '../../helpers';
import {
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Input,
    InputLabel,
    FormControl,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Attendance from './Attendance';
import {useResizeDetector} from 'react-resize-detector';
import CloseIcon from '@material-ui/icons/Close';
import {quickNotes as quickNotesArray} from '../../config/constants';
import {TimeComponent, EmptyBox} from "../";
import moment from "moment";
import EventRemainingTime from "./EventRemainingTime";
import {notificationService} from '../../services';
import {isToday} from '../../helpers';
import {Link} from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {useHistory} from "react-router-dom";


const useStyles = makeStyles(theme => ({
    title: {
        color: '#F5A623',
        textAlign: 'center',
        padding: theme.spacing(1),
        fontSize: '25px',
    },
    silverTitle: {
        color: '#969696',
        textAlign: 'center',
        padding: theme.spacing(1),
        fontSize: '14px',
    },
    secondTitle: {
        color: '#403D39',
        textAlign: 'center',
        padding: theme.spacing(1),
        fontSize: '14px',
    },
    fourthTitle: {
        color: '#403D39',
        textAlign: 'center',
        padding: theme.spacing(1),
        fontSize: '15px',
    },
    attendance: {
        border: '1px solid #9C9C9C',
        height: 80,
        padding: 0,
        boxSizing: 'border-box',
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#403D39',
    },
    dialogTitle: {
        paddingTop: 60,
        justifyContent: 'center',
    },
    dialogActions: {
        paddingBottom: 60,
        justifyContent: 'center',
    },
    formControl: {
        width: '100%',
        margin: '15px 0px',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
        borderRadius: 10,
    },
    button: {
        minWidth: 60,
        marginLeft: 15,
        padding: 0,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        '& .MuiButton-label': {
            minWidth: 1,
            textDecoration: 'none',
            textTransform: 'none',
            padding: '5px 10px',
            fontSize: '13px',
            lineHeight: '13px',
        }
    },
    squareButton: {
        minWidth: 1,
        marginLeft: 15,
        padding: 0,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        '& .MuiButton-label': {
            minWidth: 1,
            textDecoration: 'none',
            textTransform: 'none',
            padding: 0,
            fontSize: '13px',
            lineHeight: '13px',
        }
    },
}));


export const EventView = props => {
    const [event, setEvent] = useState();
    const [refresh, setRefresh] = useState(false);
    const [quickNotes, setQuickNotes] = useState([]);
    const [notes, setNotes] = useState('');
    const [notesIdx, setNotesIdx] = useState(0);
    const [notesDialogOpen, setNotesDialogOpen] = useState(false);
    const [attendances, setAttendances] = useState([]);
    const [total, setTotal] = useState(0);
    const [columns, setColumns] = useState(0);
    const [additionalBoxes, setAdditionalBoxes] = useState([]);
    const {width, ref} = useResizeDetector();
    const [events, setEvents] = useState([]);
    const [firstFetch, setFirstFetch] = useState(true);
    const [eventId, setEventId] = useState(props.match.params.eventId);
    const [eventTime, setEventTime] = useState(moment());
    const [isTodayEvent, setIsTodayEvent] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [hasNext, setHasNext] = useState(false);
    let history = useHistory();


    const classes = useStyles();


    useLayoutEffect(() => {
        let newColumns = 0;
        if (width < 600) {
            newColumns = 1;
        } else if (width < 960) {
            newColumns = 2;
        } else if (width < 1280) {
            newColumns = 3;
        } else if (width < 1920) {
            newColumns = 4;
        } else if (width >= 1920) {
            newColumns = 6;
        }

        setColumns(newColumns);

        calculateAdditionalBoxes();
    }, [width]); // eslint-disable-line


    useLayoutEffect(() => {
        calculateAdditionalBoxes();
    }, [total]); // eslint-disable-line


    useLayoutEffect(() => {
        if (events.length) {
            checkIndex();
        }
    }, [firstFetch, refresh]); // eslint-disable-line


    useEffect(() => {
        if (firstFetch && event) {
            const eventDay = eventTime.format('YYYY-MM-DD');

            fetchIt(
                'GET_LIST',
                'dashboard',
                {fromDate: eventDay, toDate: eventDay},
            ).then(response => {
                switch (response.status) {
                    case 200:
                        setEvents(response.data.sort((a, b) => {
                            const d1 = moment(moment(a.StartDateTime, 'M/D/YYYY h:mm:ss A').format('h:mm:ss A'), 'h:mm:ss A');
                            const d2 = moment(moment(b.StartDateTime, 'M/D/YYYY h:mm:ss A').format('h:mm:ss A'), 'h:mm:ss A');

                            return moment(d1).diff(d2);
                        }));

                        setFirstFetch(false);

                        break;

                    case 404:
                        let errorMessage = '';

                        if (!response.data.HasValidationErrors) {
                            errorMessage = response.data.ErrorMessage;
                        }

                        notificationService.notify({
                            message: errorMessage,
                            type: 'warning',
                        });

                        break;

                    default:
                        notificationService.notify({
                            message: 'Oops, something went wrong!',
                            type: 'error',
                        });
                }
            });
        }
    }, [refresh]); // eslint-disable-line


    useEffect(() => {
        fetchEvent();
        fetchAttendances();
    }, [eventId]); // eslint-disable-line


    const checkIndex = () => {
        let prev = false;
        let next = false;
        const eventIndex = events.findIndex(theEvent => parseInt(theEvent.Id) === parseInt(eventId));

        if (eventIndex > 0) {
            prev = true;
        }

        if (eventIndex < (events.length - 1)) {
            next = true;
        }

        setHasPrev(prev);
        setHasNext(next);
    }


    const fetchEvent = () => {
        fetchIt(
            'GET_ONE',
            'event',
            {id: eventId},
        ).then(response => {
            switch (response.status) {
                case 200:
                    const theEvent = response.data;
                    const theEventTime = moment(theEvent.StartDateTime, 'M/D/YYYY h:mm:ss A');

                    setEventTime(theEventTime);
                    setIsTodayEvent(isToday(theEventTime));
                    setEvent(theEvent);
                    setRefresh(!refresh);

                    break;

                case 404:
                    let errorMessage = 'No such event';

                    notificationService.notify({
                        message: errorMessage,
                        type: 'warning',
                    });

                    break;

                default:
                    notificationService.notify({
                        message: 'Oops, something went wrong!',
                        type: 'error',
                    });
            }
        });
    }


    const fetchAttendances = () => {
        setAttendances([]);

        fetchIt(
            'GET_LIST',
            'event',
            {id: eventId, sort: {field: 'FullName', order: 'ASC'}},
        ).then(response => {
            switch (response.status) {
                case 200:
                    setAttendances(response.data);
                    setTotal(response.total);
                    setRefresh(!refresh);

                    break;

                case 404:
                    let errorMessage = 'No such event';
                    notificationService.notify({
                        message: errorMessage,
                        type: 'warning',
                    });

                    break;

                default:
                    notificationService.notify({
                        message: 'Oops, something went wrong!',
                        type: 'error',
                    });
            }
        });
    }


    const calculateAdditionalBoxes = () => {
        const attendancesLength = total;

        if (!width || (width < 600) || (attendancesLength < 1)) {
            return false;
        }

        let additionalBoxesNumber = 0;
        if (width < 960) {
            additionalBoxesNumber = attendancesLength % 2;
        } else if ((width < 1280) && (attendancesLength % 3)) {
            additionalBoxesNumber = 3 - (attendancesLength % 3);
        } else if ((width < 1920) && (attendancesLength % 4)) {
            additionalBoxesNumber = 4 - (attendancesLength % 4);
        } else if ((width >= 1920) && (attendancesLength % 6)) {
            additionalBoxesNumber = 6 - (attendancesLength % 6);
        }

        let newAdditionalBoxes = [];
        if (additionalBoxesNumber) {
            for (let t = 0; t < additionalBoxesNumber; t++) {
                newAdditionalBoxes.push(t);
            }
        }

        setAdditionalBoxes(newAdditionalBoxes);
    }


    const changeAttendance = idx => {
        let newAttendances = attendances;

        attendances[idx].Attendance = !(attendances[idx] && attendances[idx].Attendance);

        setAttendances(newAttendances);
    }


    const onNotesClick = idx => {
        setNotesIdx(idx);
        setNotes(attendances[idx].Notes);
        setQuickNotes(attendances[idx].QuickNotes ? attendances[idx].QuickNotes : []);
        setNotesDialogOpen(true);
    }


    const handleClose = () => {
        setNotesDialogOpen(false);
    }


    const onQuickNoteDelete = quickNote => {
        setQuickNotes(quickNotes.filter(qn => qn !== quickNote));
        setRefresh(!refresh);
    }


    const onQuickNoteAdd = quickNote => {
        if (!inArray(quickNote, quickNotes)) {
            let newQuickNotes = quickNotes;
            newQuickNotes.push(quickNote);

            setQuickNotes(newQuickNotes);
            setRefresh(!refresh);
        }
    }


    const saveNotes = () => {
        let newAttendances = attendances;

        attendances[notesIdx].Notes = notes;
        attendances[notesIdx].QuickNotes = quickNotes;

        setAttendances(newAttendances);

        handleClose();
    }


    const setNewNotes = e => {
        e.persist();

        setNotes(e.target.value);
    }


    const submitAttendances = () => {
        let hasUnmarkedAttendances = false;
        for (let t = 0; t < attendances.length; t++) {
            if (attendances[t].Attendance === null) {
                hasUnmarkedAttendances = true;

                break;
            }
        }

        if (hasUnmarkedAttendances) {
            notificationService.notify({
                message: 'You should mark all as Present or Absent!',
                type: 'warning',
            });

            return false;
        }

        fetchIt(
            'UPDATE',
            'event',
            {EventAttendances: attendances},
        ).then(response => {
            switch (response.status) {
                case 200:
                    let successMessage = 'Submitted';
                    fetchAttendances();

                    notificationService.notify({
                        message: successMessage,
                        type: 'success',
                    });

                    break;

                case 404:
                    let errorMessage = 'No such event';

                    notificationService.notify({
                        message: errorMessage,
                        type: 'warning',
                    });

                    break;

                default:
                    notificationService.notify({
                        message: 'Oops, something went wrong!',
                        type: 'error',
                    });
            }
        });
    }


    const back = () => {
        const eventIndex = events.findIndex(theEvent => parseInt(theEvent.Id) === parseInt(eventId));

        if (eventIndex > 0) {
            const theEvent = events[eventIndex - 1];

            setEventId(parseInt(theEvent.Id));

            history.replace('/event/' + theEvent.Id);
        }
    }


    const forward = () => {
        const eventIndex = events.findIndex(theEvent => parseInt(theEvent.Id) === parseInt(eventId));

        if (eventIndex < (events.length - 1)) {
            const theEvent = events[eventIndex + 1];

            setEventId(parseInt(theEvent.Id));

            history.replace('/event/' + theEvent.Id);
        }
    }


    return (
        <>
            <Typography
                className={classes.silverTitle}
                variant='h5'
            >
                {
                    isTodayEvent ?
                        <TimeComponent />
                    :
                        <>{eventTime.format('dddd, D MMMM')}</>
                }
            </Typography>

            {
                event ?
                    <div>
                        <Typography className={classes.secondTitle} variant='h5'>
                            {event.StudioName}, {total ? total : 'no'} student{total !== 1 ? 's' : ''} in class
                        </Typography>

                        <Typography className={classes.title} variant='h4'>
                            {event.CourseName}
                        </Typography>

                        <Typography className={classes.fourthTitle} variant='h5'>
                            <EventRemainingTime event={event} />
                        </Typography>
                    </div>
                :
                    null
            }

            <Grid
                ref={ref}
                container
                style={{marginTop: 20}}
            >
                <Grid
                    item
                    xs={6}
                >
                    <Link
                        to={'/day/' + eventTime.format('YYYY-MM-DD')}
                        style={{textDecoration: 'none'}}
                    >
                        <Button
                            variant='outlined'
                            color='primary'
                            className={classes.button}
                            style={{marginLeft: 40}}
                        >
                            Back to schedule
                        </Button>
                    </Link>
                </Grid>
                <Grid
                    item
                    xs={6}
                    style={{textAlign: 'right'}}
                >
                    <Button
                        variant='outlined'
                        onClick={back}
                        className={classes.squareButton}
                        disabled={!hasPrev}
                    >
                        <ArrowBackIcon />
                    </Button>
                    <Button
                        variant='outlined'
                        onClick={forward}
                        className={classes.squareButton}
                        style={{marginRight: 40}}
                        disabled={!hasNext}
                    >
                        <ArrowForwardIcon />
                    </Button>
                </Grid>
            </Grid>

            {
                attendances.length ?
                    <>
                        <Grid
                            container
                            style={{borderTop: '1px solid #9C9C9C', borderBottom: '1px solid #9C9C9C', marginTop: 35, marginBottom: 25}}
                        >
                            {
                                attendances.map((attendance, idx) => {
                                    return (
                                        <Grid
                                            key={idx}
                                            item
                                            xs={12} sm={6} md={4} lg={3} xl={2}
                                            className={classes.attendance}
                                            style={{
                                                borderLeft: ((idx === 0) || ((idx % columns) === 0) ? 'none' : '1px solid #9C9C9C'),
                                                borderRight: ((((idx + 1) % columns) === 0) ? 'none' : '1px solid #9C9C9C'),
                                            }}
                                        >
                                            <Attendance
                                                changeAttendance={changeAttendance}
                                                attendance={attendance}
                                                idx={idx}
                                                attendancesLength={total}
                                                onNotesClick={onNotesClick}
                                                notes={attendance.Notes}
                                            />
                                        </Grid>
                                    );
                                })
                            }

                            {
                                additionalBoxes ?
                                    additionalBoxes.map(additionalBox => (
                                        <Grid
                                            key={additionalBox}
                                            item
                                            xs={12} sm={6} md={4} lg={3} xl={2}
                                            className={classes.attendance}
                                            style={{borderRight: ((additionalBox === (additionalBoxes.length - 1)) ? 'none' : '1px solid #9C9C9C')}}
                                        >
                                            <EmptyBox />
                                        </Grid>
                                    ))
                                : null
                            }
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                <Button onClick={submitAttendances} color="primary" variant='contained'>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>

                        <Dialog
                            open={notesDialogOpen}
                            onClose={handleClose}
                            aria-labelledby="form-dialog-title"
                            maxWidth='sm'
                            fullWidth
                        >
                            <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                                <Typography
                                    className={classes.secondTitle}
                                    variant='h4'
                                    component='div'
                                    style={{fontSize: '21px'}}
                                >
                                    Create new note for {attendances[notesIdx] ? attendances[notesIdx].FirstName + ' ' + attendances[notesIdx].LastName : ''}
                                </Typography>

                                <Typography className={classes.silverTitle} variant='subtitle1' component='div'>
                                    {
                                        isTodayEvent ?
                                            <TimeComponent />
                                        :
                                            <>{eventTime.format('dddd, D MMMM')}</>
                                    }
                                </Typography>

                                <IconButton
                                    aria-label="close"
                                    className={classes.closeButton}
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>

                            <DialogContent>
                                <div className={classes.chips}>
                                    {quickNotesArray.map(quickNote => (
                                        <Chip
                                            key={quickNote}
                                            label={quickNote}
                                            className={classes.chip}
                                            clickable
                                            onClick={() => onQuickNoteAdd(quickNote)}
                                        />
                                    ))}
                                </div>

                                <FormControl className={classes.formControl}>
                                    <InputLabel
                                        id="quick-notes-label"
                                        shrink={!!quickNotes.length}
                                    >
                                        Quick notes
                                    </InputLabel>
                                    <Select
                                        labelId="quick-notes-label"
                                        id="quick-notes"
                                        multiple
                                        value={quickNotes}
                                        input={<Input id="select-quick-notes" />}
                                        renderValue={selected => (
                                            <div className={classes.chips}>
                                                {selected.map(quickNote => (
                                                    <Chip
                                                        key={quickNote}
                                                        label={quickNote}
                                                        className={classes.chip}
                                                        onDelete={() => onQuickNoteDelete(quickNote)}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                        fullWidth
                                        disabled
                                    >

                                    </Select>
                                </FormControl>


                                <TextField
                                    id="notes-field"
                                    label="Additional message"
                                    multiline
                                    value={notes ? notes : ''}
                                    onChange={setNewNotes}
                                    fullWidth
                                    autoFocus
                                />
                            </DialogContent>

                            <DialogActions className={classes.dialogActions}>
                                <Button onClick={saveNotes} color="primary" variant='contained'>
                                    Save &amp; Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                : null
            }
        </>
    );
}

export default EventView;
