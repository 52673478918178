import React from 'react';
import moment from "moment";


export const inArray = (needle = '', haystack = []) => {
    return (Array.isArray(haystack) && (haystack.indexOf(needle) > -1));
};


export const getErrorMessage = data => {
    let errorMessage = 'Oops, something went wrong';
    if (typeof data.HasValidationErrors !== 'undefined') {
        if (data.HasValidationErrors) {
            errorMessage = data.Errors.map((message, key) => {
                return (
                    <p key={key}>{message.Message}</p>
                );
            });
            errorMessage = data.Errors[0].Message;
        } else {
            errorMessage = data.ErrorMessage;
        }
    } else if (data.error_description !== 'undefined') {
        errorMessage = data.error_description;
    }

    return errorMessage;
};


export const getUserName = () => new Promise((resolve, reject) => {
    if (localStorage.getItem('account')) {
        const account = JSON.parse(localStorage.getItem('account'));

        resolve(account.FirstName + ' ' + account.LastName);
    }

    let counter = 0;

    let interval = setInterval(() => {
        counter++;
        if (counter > 100) {
            clearInterval(interval);

            reject(new Error("Whoops!"));
        } else if (localStorage.getItem('account')) {
            clearInterval(interval);

            const account = JSON.parse(localStorage.getItem('account'));

            resolve(account.FirstName + ' ' + account.LastName);
        }
    }, 200);
});


export const getPermissions = () => new Promise((resolve, reject) => {
    if (localStorage.getItem('account')) {
        const account = JSON.parse(localStorage.getItem('account'));

        resolve(account.Role);
    }

    if(localStorage.getItem('user') === null) {
        resolve("unlogged");
    }

    let counter = 0;

    let interval = setInterval(() => {
        counter++;
        if (counter > 100) {
            clearInterval(interval);

            reject(new Error("No account data!"));
        } else if (localStorage.getItem('account')) {
            clearInterval(interval);
            const account = JSON.parse(localStorage.getItem('account'));

            resolve(account.Role);
        }
    }, 200);
});


export const getWeekdays = () => {
    let weekdays = [];
    for (let t = 1; t < 8; t++) {
        weekdays.push(moment(t, 'E').format('dddd'));
    }

    return weekdays;
};


export const getWeekdayChoices = getWeekdays().map(weekDay => ({id: weekDay, name: weekDay}));


export const isToday = date => {
    const currentDate = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD');
    const selectedDate = moment(date.format('YYYY-MM-DD'), 'YYYY-MM-DD');

    return currentDate.isSame(selectedDate);
}
