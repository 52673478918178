import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import moment from "moment";
import {Link} from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'nowrap',
        textAlign: 'left',
        padding: '0px 15px',
        width: '100%',
        height: 110,
        lineHeight: '110px',
        borderBottom: '1px solid #403D39',
        boxSizing: 'border-box',
        textDecoration: 'none',
    },
    studioColorWrapper: {
        padding: 6,
        borderRadius: '50%',
        width: 34,
        height: 34,
        boxSizing: 'border-box',
        backgroundColor: '#FFFFFF',
        marginTop: 38,
    },
    studioColor: {
        borderRadius: '50%',
        width: 22,
        height: 22,
        boxSizing: 'border-box',
    },
    studioName: {
        flexBasis: 125,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight:  15,
        boxSizing: 'border-box',
    },
    courseName: {
        flexGrow: 2,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight:  15,
        boxSizing: 'border-box',
    },
    startsInTitle: {
        flexGrow: 1,
        textAlign: 'right',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));


const Event = props => {
    const [containerStyle, setContainerStyle] = useState(
        {
            color: '#403D39',
            backgroundColor: '#EFEFEF',
            borderTop: (props.idx === 0 ? '1px solid #403D39' : 'none'),
            marginTop: (props.idx === 0 ? 25 : 0),
        }
    );
    const [beforeInAfter, setBeforeInAfter] = useState(1); //Before: 1, In: 2, After: 3

    const event = props.event;
    const startTime = moment(event.StartDateTime, 'M/D/YYYY h:mm:ss A');
    const endTime = moment(event.EndDateTime, 'M/D/YYYY h:mm:ss A');


    useEffect(() => {
        let newColor = '#403D39';
        let newBackgroundColor = '#EFEFEF';
        let bia = 3;
        if (props.currentTime.isBefore(endTime)) {
            if (props.currentTime.isAfter(startTime)) {
                newBackgroundColor = '#3F7600';
                newColor = '#FFFFFF';
                bia = 2;
            } else {
                newBackgroundColor = '#FCFFF3';
                newColor = '#403D39';
                bia = 1;
            }
        }

        setBeforeInAfter(bia);
        let newStyle = Object.assign({}, containerStyle);
        newStyle.color = newColor;
        newStyle.backgroundColor = newBackgroundColor;
        setContainerStyle(newStyle);
    }, [props.currentTime]); // eslint-disable-line


    const classes = useStyles();


    return (
        <Link
            to={'/event/' + event.Id}
            className={classes.container}
            style={containerStyle}
        >
            <div style={{flexBasis: 125, flexShrink: 0}}>{startTime.format('h:mm a')}</div>
            <div style={{flexBasis: 46, flexShrink: 0}}>
                <div className={classes.studioColorWrapper}>
                    <div className={classes.studioColor} style={{backgroundColor: event.DisplayColor}}> </div>
                </div>
            </div>
            <div className={classes.studioName}>
                {event.StudioName}
            </div>
            <div className={classes.courseName}>{event.CourseName}</div>
            <div className={classes.startsInTitle}>
                {
                    beforeInAfter === 1 ?
                        'starts ' + startTime.fromNow()
                    :
                        beforeInAfter === 2 ?
                            'NOW'
                        :
                            'ENDED'
                }
            </div>
        </Link>
    );
}

export default Event;
